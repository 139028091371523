import React from 'react';

import { GetStaticPropsContext, GetStaticPropsResult } from 'next';
import { SSRConfig, withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';

import { DEFAULT_LOCALE } from '@/c/constants/locales';
import { scopedT } from '@/c/translations/utils';

import { NextPageProps } from '../types/NextPageProps';

const T_NAMESPACE = '404-page';

const Page = ({ t }: NextPageProps) => {
    const nt = scopedT(t, T_NAMESPACE);
    return (
        <>
            <Head>
                <title>{nt('pageTitle')}</title>
            </Head>
            <p>{nt('not-found')}</p>
        </>
    );
};

const tNamespaces = ['common', 'header', 'footer', T_NAMESPACE];

export async function getStaticProps({ locale }: GetStaticPropsContext): Promise<GetStaticPropsResult<SSRConfig>> {
    return {
        props: {
            ...(await serverSideTranslations(locale || DEFAULT_LOCALE, tNamespaces)),
        },
    };
}

export default withTranslation(tNamespaces)(Page as React.FC);
